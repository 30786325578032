import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

import Chart from './Chart';
import api from '../util/API';

function RoomSelect({rooms, name, value, setValue}) {
  if (rooms) {
    return (
      <Form.Select className="mx-2 d-inline" style={{'width': '9em'}}
        value={value}
        onChange={(evt) => {
          localStorage.setItem(`RoomSelect_${name}`, evt.target.value);
          setValue(evt.target.value);
        }}
      >
        {rooms.map(r => (
          <option key={r.id} value={r.id}>{r.name}</option>
        ))}
      </Form.Select>
    )
  }
}

const aggregates = {
  'month': {
    label: 'Month',
    chartProps: {
      xLabelsFormat: "MMM",
      tooltipXFormat: "MMM",
    },
    lineChartRange: {
      startTime: moment("2000-01-01").valueOf(),
      endTime: moment("2000-12-02").valueOf(), // force display of the Dec label
      options: {
        markers: {
            size: 4,
        },
      }
    },
    barChartRange: {
      startTime: moment("1999-12-16").valueOf(),
      endTime: moment("2000-12-15").valueOf(),
    },
  },
  'day' : {
    label: 'Day',
    chartProps: {
      tooltipXFormat: "dd MMM",
      xLabelsFormat: "dd MMM",
    },
    lineChartRange: {
      startTime: moment("2000-01-01").valueOf(),
      endTime: moment("2001-01-01").valueOf(),
    },
    barChartRange: {
      startTime: moment("1999-12-31").valueOf(), // force display of the leftmost columns
      endTime: moment("2001-01-01").valueOf(),
    },
  },
}


function YearStatistics(props) {
  const [aggregate, setAggregate] = useState('month');
  const [tempRoomId, setTempRoomId] = useState(localStorage.getItem(`RoomSelect_tempRoomId`));
  const [humRoomId, setHumRoomId] = useState(localStorage.getItem(`RoomSelect_humRoomId`));
  const [rooms, setRooms] = useState(null);
  const [roomCategories, setRoomCategories] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(new Set(JSON.parse(localStorage.getItem(`YearStatistics_selectedCategories`))));

  useEffect(() => {
    (async () => {
      const res = await api.get("rooms");

      // extract rooms into cateories by "energyType" field. This is what will be used in the UI.
      const _roomCategories = {};
      res.forEach(r => {
        if (r.energyType) {
          _roomCategories[r.energyType] = [...(_roomCategories[r.energyType] || []), r.id];
        }
      })

      // see if we need to set the defaults for the first time
      if (!tempRoomId) {
        setTempRoomId(res[0].id);
      }
      if (!humRoomId) {
        setHumRoomId(res[0].id);
      }

      if (!selectedCategories.size) {
        setSelectedCategories(new Set(Object.keys(_roomCategories)));
      }

      setRooms(res);
      setRoomCategories(_roomCategories);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// this is REALLY what I want, just run it once

  // extract actual room ID's from the selected categories and turn them into one list
  const selectedRoomsLists = roomCategories ? [...selectedCategories.entries()].map(([k, v]) => roomCategories[k]) : []; // array of arrays
  const selectedRooms = [].concat(...selectedRoomsLists);

  return (
    <>
      <Form className="bg-light">
        <Form.Label className="mt-4 mx-3 d-inline">Aggregate by </Form.Label>
        {Object.keys(aggregates).map(k => (
          <Form.Check
            key={k}
            value={k}
            id={`AggRadio_${k}`}
            inline type="radio"
            checked={aggregate === k}
            label={aggregates[k].label}
            onChange={(evt) => {
              setAggregate(evt.target.value);
            }}
          />
        ))}
      </Form>

      {tempRoomId && <Chart
        source={`chart/year/temperature?aggregate=${aggregate}&roomIds=[${tempRoomId}]`}
        type="line"
        yFormatter={function (value) {
          return value != null ? `${value.toFixed(1)} °C` : "?";
        }}
        title={() => (
          <>
            {"Temperature for"}
            <RoomSelect rooms={rooms} name={"tempRoomId"} value={tempRoomId} setValue={setTempRoomId}/>
          </>
        )}
        {...aggregates[aggregate].chartProps}
        {...aggregates[aggregate].lineChartRange}
      />}

      <Form className="bg-light">
        {roomCategories && Object.keys(roomCategories).map(k => (
          <Form.Check
            key={k}
            id={`RoomsCheckbox_${k}`}
            inline type="checkbox" label={k}
            checked={selectedCategories.has(k)}
            onChange={(evt) => {
              if (selectedCategories.has(k)) {
                selectedCategories.delete(k);
              }
              else {
                selectedCategories.add(k);
              }
              localStorage.setItem(`YearStatistics_selectedCategories`, JSON.stringify(Array.from(selectedCategories)));
              setSelectedCategories(new Set(selectedCategories));
            }}
          />
        ))}
      </Form>

      {selectedRooms.length > 0 && <Chart
        source={`chart/year/powerusage?aggregate=${aggregate}&roomIds=${JSON.stringify(selectedRooms)}`}
        type="bar"
        yFormatter={function (value) {
          const v = Number.isSafeInteger(value) ? parseInt(value) : value.toFixed(1);
          return `${v} kWh`;
        }}
        title={(x) => `Power used (kWh)`}
        {...aggregates[aggregate].chartProps}
        {...aggregates[aggregate].barChartRange}
        debounce={2000}
      />}

      {selectedRooms.length > 0 && <Chart
        source={`chart/year/powercost?aggregate=${aggregate}&roomIds=${JSON.stringify(selectedRooms)}`}
        type="bar"
        yFormatter={function (value) {
          return Number.isSafeInteger(value) ?
            `$${parseInt(value)}` : `$${value.toFixed(2)}`;
        }}
        title={(x) => `Power cost ($)`}
        {...aggregates[aggregate].chartProps}
        {...aggregates[aggregate].barChartRange}
        debounce={2000}
      />}

      {humRoomId && <Chart
        source={`chart/year/humidity?aggregate=${aggregate}&roomIds=[${humRoomId}]`}
        type="line"
        yFormatter={function (value) {
          value = value || 0;
          return `${Math.round(value)}%`;
        }}
        title={() => (
          <>
            {"Humidity for"}
            <RoomSelect rooms={rooms} name={"humRoomId"} value={humRoomId} setValue={setHumRoomId}/>
          </>
        )}
        {...aggregates[aggregate].chartProps}
        {...aggregates[aggregate].lineChartRange}
      />}

    </>
  );
}
export default YearStatistics;
